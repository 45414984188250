@import "https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css";
.popupBackdropClass {
  opacity: 0;
}

.main-loader {
  height: 100vh;
}

.loader.icon {
  position: absolute;
  top: 40%;
  left: -10%;
}

.application-loading-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.application-loading-box {
  text-align: center;
  width: 300px;
  margin: 5px;
}

.spinner-border {
  color: #0c6e37 !important;
  width: 5rem !important;
  height: 5rem !important;
}

input::-webkit-input-placeholder {
  font-size: 12px !important;
}

::-moz-placeholder {
  font-size: 12px !important;
}

:-ms-input-placeholder {
  font-size: 12px !important;
}

::placeholder {
  font-size: 12px !important;
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), .05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), .1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), .075);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

textarea, select, input, button, a {
  outline: none;
}

textarea:focus, .form-control:focus, select:focus, .custom-select:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
  outline: 0;
  outline: thin dotted \9 ;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #0000;
}

input[type="search"]::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input[type="search"]::-webkit-search-results-button {
  display: none;
}

input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.dropdown-toggle:after {
  content: "";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  vertical-align: inherit;
  color: #646c9a;
  border: none;
  margin-left: .5em;
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 1;
  display: inline-block;
}

.dropdown-toggle-up:after {
  content: "";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  vertical-align: inherit;
  color: #646c9a;
  border: none;
  margin-left: .5em;
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 1;
  display: inline-block;
}

.dropdown-menu {
  border-color: #d9dce7;
  padding: 0;
}

.dropdown-menu .dropdown-item {
  cursor: pointer;
  padding: 6px 10px;
  font-size: 14px;
  color: #646c9a !important;
}

.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover {
  background-color: #f6f6f9;
}

.dropdown-menu .dropdown-item:active {
  color: #fff !important;
  background: #0c6e37 !important;
}

.dropdown-menu .dropdown-item:first-child {
  border-radius: 4px 4px 0 0;
}

.dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 4px 4px;
}

.form-control::-webkit-input-placeholder {
  color: #a1a8c3;
}

.form-control:-moz-placeholder {
  color: #a1a8c3;
}

.form-control::-moz-placeholder {
  color: #a1a8c3;
}

.form-control:-ms-input-placeholder {
  color: #a1a8c3;
}

a {
  text-decoration: none;
  transition: all .3s linear;
}

a:hover {
  text-decoration: none;
}

body {
  color: #495057;
  background-color: #f2f2f7;
  font-family: Poppins;
}

.btn {
  font-size: 14px;
}

.btn-icon {
  color: #646c9a;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  display: inline-flex;
  padding: 0 !important;
}

.btn-icon:hover, .btn-icon.active {
  color: #0c6e37;
}

.btn-icon:focus {
  box-shadow: none;
}

.btn-link-with-icon {
  color: #646c9a;
}

.btn-link-with-icon i {
  color: #646c9a;
  margin-right: 6px;
  font-size: 12px;
}

.btn-link-with-icon:hover, .btn-link-with-icon:focus {
  color: #0c6e37;
  box-shadow: none;
  text-decoration: none;
}

.btn-link-with-icon:hover i, .btn-link-with-icon:focus i {
  color: #0c6e37;
}

.btn-primary {
  width: max-content;
  box-shadow: none !important;
  color: #fff !important;
  background: #0c6e37 !important;
  border-color: #0c6e37 !important;
}

.btn-success {
  box-shadow: none !important;
}

.btn-secondary {
  background: none;
  box-shadow: none !important;
  color: #646c9a !important;
}

.btn-secondary:hover {
  background: none;
  color: #646c9a !important;
}

.btn-search {
  width: 100%;
}

.primary-btn {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background: #0c6e37;
  border-radius: 10px;
  width: 100%;
  margin-top: 2rem;
  padding: 10px 25px;
}

.forgot-pwd {
  font-size: 15px;
  line-height: 22px;
  text-decoration-line: underline;
}

.forgot-pwd a {
  color: #0c6e37 !important;
}

.fake-btn {
  border-radius: 5px;
  width: 100px;
  height: 30px;
}

.btn-upload {
  text-transform: capitalize;
  border: none;
  height: 45px;
  padding: 0 40px;
  font-size: 15px;
  color: #222 !important;
  background: #fff !important;
  box-shadow: 0 13px 26px #00000026 !important;
}

.btn-upload:hover {
  color: #fff !important;
  background: #0c6e37 !important;
}

.btn-delete {
  color: #222;
  background-color: #e1e1ea;
  padding: .375rem .75rem;
  font-size: 15px;
  font-weight: 500;
}

.btn-delete:hover {
  color: #fff;
  background-color: #ff4242;
}

.btn-share {
  color: #222;
  background-color: #e1e1ea;
  padding: 5px 25px;
  font-size: 15px;
  font-weight: 500;
}

.btn-share:hover {
  color: #fff;
  background-color: #ff4242;
}

.btn-yes {
  color: #fff;
  background-color: #ff4242;
  padding: 5px 35px;
  font-size: 15px;
  font-weight: 500;
}

.btn-yes:hover {
  color: #fff;
  background-color: #ff4242;
}

.btn-no {
  color: #fff;
  background-color: #0c6e37;
  padding: 5px 35px;
  font-size: 15px;
  font-weight: 500;
}

.btn-no:hover {
  color: #fff;
  background-color: #0c6e37;
}

.close-btn {
  color: #fff;
  text-shadow: none;
  font-size: 35px;
  font-weight: 300;
}

.btn-upload-mobile {
  display: none;
}

button:focus {
  outline: 0;
}

.notification-count {
  color: #fff;
  background: #0c6e37;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  font-size: 10px;
  font-weight: bolder;
  display: flex;
}

.btn-edit {
  padding: 5px 35px;
}

.on-profile {
  position: absolute;
  bottom: 25%;
  right: 10%;
}

.btn-refresh {
  color: #222;
  background: #fff;
  border: none;
  border-radius: 20px;
  justify-content: center;
  padding: 5px 25px;
  display: flex;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.btn-refresh:hover {
  color: #fff;
  background: #0c6e37;
}

.btn-scroll {
  color: #0c6e37;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 45px;
}

.btn-sync {
  color: #222;
  background: #fff;
  border: none;
  border-radius: 20px;
  padding: 5px 25px;
}

.btn-add-more {
  color: #fff !important;
  background: #0c6e37 !important;
  border: 0 !important;
  border-radius: 50% !important;
  padding: 5px 10px !important;
  font-size: 15px !important;
  box-shadow: 0 12px 14px #0e9aa729 !important;
}

span.multiselect-native-select {
  position: relative;
}

span.multiselect-native-select select {
  top: 30px;
  left: 50%;
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px -1px -1px -3px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.multiselect-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
}

.multiselect-container .input-group {
  margin: 5px;
}

.multiselect-container .multiselect-reset .input-group {
  width: 93%;
}

.multiselect-container > li {
  padding: 0;
}

.multiselect-container > li > a.multiselect-all label {
  font-weight: 700;
}

.multiselect-container > li.multiselect-group label {
  height: 100%;
  margin: 0;
  padding: 3px 20px;
  font-weight: 700;
}

.multiselect-container > li.multiselect-group-clickable label {
  cursor: pointer;
}

.multiselect-container > li > a {
  padding: 0;
}

.multiselect-container > li > a > label {
  cursor: pointer;
  height: 100%;
  margin: 0;
  padding: 3px 20px 3px 40px;
  font-weight: 400;
}

.multiselect-container > li > a > label.checkbox, .multiselect-container > li > a > label.radio {
  margin: 0;
}

.multiselect-container > li > a > label > input[type="checkbox"] {
  margin-bottom: 5px;
}

.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-inline .multiselect-container label.checkbox, .form-inline .multiselect-container label.radio {
  padding: 3px 20px 3px 40px;
}

.form-inline .multiselect-container li a label.checkbox input[type="checkbox"], .form-inline .multiselect-container li a label.radio input[type="radio"] {
  margin-left: -20px;
  margin-right: 0;
}

.filter-fields input, .filter-fields select {
  border: 1px solid #d9dce7;
  height: auto;
  padding: 4px;
  font-size: 12px;
}

.filter-fields input:focus, .filter-fields select:focus {
  border: 1px solid #bdc2d5;
}

.filter-fields.dropdown .btn {
  border: 1px solid #d9dce7;
  border-radius: .25rem;
  font-size: 12px;
}

.fetching-data {
  background: #fff;
  border: 1px solid #d9dce7;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 142px);
  display: flex;
}

.allign-loader i {
  color: #b2b6cd;
  font-size: 40px;
}

.floatThead-container {
  z-index: 9 !important;
}

.weight-500 {
  font-weight: 500;
}

.disabled-content {
  pointer-events: none;
  opacity: .4;
}

.pagination-container {
  width: 70%;
  position: fixed;
  bottom: 14px;
}

.pagination-container .d-flex {
  margin-top: 0;
}

.pagination-container .d-flex p {
  font-size: 12px;
}

.pagination-container .d-flex .paginate-result {
  margin-top: 13px;
  margin-bottom: 13px;
  padding: 8px 30px 8px 0;
  position: relative;
}

.pagination-container .pagination-loader {
  padding: 6px 15px 6px 0;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.pagination-container .pagination-loader:before {
  content: "";
  border-right: 1px solid #e3e5ed;
  height: 20px;
  position: absolute;
  top: 6px;
  left: -16px;
}

.pagination-container .pagination-loader i {
  color: #b2b6cd;
  font-size: 20px;
}

.pagination-container .pagination-loader .l-text {
  vertical-align: text-bottom;
  padding-left: 8px;
}

.pagination {
  margin-top: 13px;
}

.pagination .page-item.disabled a {
  color: #a1a8c3;
}

.pagination .page-item.active a {
  color: #fff;
  background: #0c6e37;
  border-color: #0c6e37;
}

.pagination .page-item a {
  color: #646c9a;
  font-size: 14px;
}

.pagination .page-item a.page-link:focus {
  box-shadow: none;
}

.form-group label {
  color: #646c9a;
  font-size: 14px;
}

.form-group .form-control {
  font-size: 14px;
}

.form-group .error-msg {
  color: #dc3545;
  margin-top: 2px;
  font-size: 12px;
}

.form-group .form-control:focus, .form-group input[type="search"]:focus {
  border-color: #0c6e37;
}

.form-group--placeholder-label {
  padding-top: 2rem;
  position: relative;
}

.form-group--placeholder-label label {
  color: #18181875;
  margin: 0;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 400;
  transition: all .125s;
  position: absolute;
  top: calc(2.5rem - 1px);
  left: calc(.75rem + 1px);
}

.form-group--placeholder-label .form-control {
  box-sizing: border-box;
  border: 1px solid #d9dce7;
  border-radius: 12px;
}

.form-group--placeholder-label .form-control:focus {
  border: 1px solid #1818188c !important;
}

.form-group--placeholder-label .form-control.is-invalid {
  background: unset;
}

.form-group--placeholder-label .form-control.is-invalid:focus {
  box-shadow: none;
  background: unset;
  border-color: #dc3545 !important;
}

.form-group--placeholder-label .form-control.is-valid:focus {
  box-shadow: none;
  border-color: #28a745 !important;
}

.form-group--placeholder-label .form-control.ng-touched.ng-invalid, .form-group--placeholder-label .form-control.ng-touched.ng-invalid:focus {
  border-color: #dc3545 !important;
}

.form-group--placeholder-label--active label {
  color: #000;
  top: 0;
  left: 0;
}

.form-content {
  display: flex;
}

.form-content label {
  color: #646c9a;
  flex: 1;
  font-size: 14px;
}

.form-content .value {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
}

.form-check label, .custom-checkbox label {
  color: #646c9a;
  font-size: 14px;
}

.invalid-message p {
  color: #dc3545;
  text-align: center;
  text-transform: capitalize;
  border: 1px solid #dc3545;
  border-radius: .25rem;
  padding: 2px 6px;
  font-size: 14px;
}

.valid-message p {
  color: #28a745;
  text-align: center;
  border: 1px solid #28a745;
  border-radius: .25rem;
  padding: 2px 6px;
  font-size: 14px;
}

.authentication-container img {
  margin-top: 50px;
}

.authenticate-bg {
  background: #fff;
  height: 100vh;
}

.small-description {
  font-size: 15px;
  color: #181818 !important;
  text-align: center !important;
}

.small-description a {
  color: #181818;
  cursor: pointer;
  text-decoration: underline !important;
}

.diabled-class {
  pointer-events: none;
  opacity: .5;
}

.box .social {
  float: none;
  text-align: center;
  cursor: pointer;
  margin: 0 auto 30px;
}

.box .social .facebook {
  color: #fff;
  background-color: #455ca8 !important;
  padding: 15px 22px !important;
}

.box .social .facebook:hover {
  color: #fff;
  background-color: #455ca8 !important;
}

.box .social .google {
  color: #fff !important;
  background-color: #f74933 !important;
}

.box .social .apple {
  color: #fff !important;
  background-color: #000 !important;
}

.box .social .twitter {
  color: #fff !important;
  background-color: #00acee !important;
}

.box .social .circle {
  color: #fff;
  background-color: #eee;
  border-radius: 100px;
  margin: 0 17px;
  padding: 15px 20px;
  display: inline-block;
}

.box .social .circle .fa {
  font-size: 16px;
}

.division {
  float: none;
  text-align: center;
  width: 100%;
  margin: 0 auto 18px;
  position: relative;
  overflow: hidden;
}

.division span {
  color: #181818;
  font-size: 18px;
  font-weight: 500;
}

.division .line {
  border-top: 1px solid #1818188c;
  width: 42%;
  position: absolute;
  top: 14px;
}

.division .line.l {
  left: 0;
}

.division .line.r {
  right: 0;
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(.95);
    -moz-transform: scale(.95);
    -ms-transform: scale(.95);
    -o-transform: scale(.95);
    box-shadow: 0 0 #3aabdf99;
  }

  80% {
    transform: scale(1);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    box-shadow: 0 0 0 10px #3aabdf00;
  }

  100% {
    -webkit-transform: scale(.95);
    -moz-transform: scale(.95);
    -ms-transform: scale(.95);
    -o-transform: scale(.95);
    box-shadow: 0 0 #3aabdf00;
  }
}

@keyframes progress {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.rotating {
  -ms-animation: rotating 2s linear infinite;
  animation: 2s linear infinite rotating;
}

.pulse:before {
  -ms-animation: pulse 1.2s infinite ease;
  -webkit-animation: 1.2s infinite pulse;
  -moz-animation: 1.2s infinite pulse;
}

.scale-on-hover {
  transition: all .2s;
  transform: scale(.93);
}

.scale-on-hover:hover {
  transform: scale(1);
}

.modal-background {
  opacity: .75;
  z-index: 900;
  background-color: #000;
  position: fixed;
  inset: 0;
}

body.modal-open {
  overflow: hidden;
}

.modal {
  top: -15% !important;
}

.modal-dialog {
  padding: 15px;
}

.modal-dialog .modal-content {
  padding: 10px;
}

.modal-dialog .modal-content textarea:focus, .modal-dialog .modal-content input:focus, .modal-dialog .modal-content select:focus {
  border: 1px solid #ced4da;
}

.modal-dialog .modal-content .modal-title h1 {
  float: left;
  color: #222;
  margin-bottom: 0;
  font-size: 16px;
}

.modal-dialog .modal-content .modal-title i {
  float: right;
  cursor: pointer;
}

.modal-dialog .modal-content .modal-title i:hover {
  opacity: .8;
  transition: all .3s linear;
}

.modal-dialog .modal-content .modal-body {
  border-radius: 4px;
  padding: 10px;
  position: relative;
  top: 50%;
}

.modal-dialog .modal-content .modal-body .alert {
  padding: 0;
  font-size: 15px;
}

.modal-dialog .modal-content .modal-body .content-body {
  margin-top: 30px;
  margin-bottom: 10px;
}

.modal-dialog .modal-content .modal-body .content-body .content-results {
  border-top: 1px solid #e3e5ed;
  margin-top: 20px;
  padding-top: 20px;
}

.modal-dialog .modal-content .modal-body .content-body .content-results .form-group {
  margin-bottom: 20px;
}

.modal-dialog .modal-content .modal-body .content-body .content-results .form-group .form-control {
  font-weight: 500;
}

.modal-dialog .modal-content .modal-body .content-body .clear {
  cursor: pointer;
  color: #ff7b7b;
  font-size: 12px;
}

.modal-dialog .modal-content .modal-body .content-body .clear i {
  font-size: 12px;
}

.modal-dialog .modal-content .modal-body .content-body .clear:hover {
  color: #ffa1a1;
  transition: all .3s linear;
}

.modal-dialog .modal-content .modal-body .content-body .go-group {
  position: relative;
}

.modal-dialog .modal-content .modal-body .content-body .go-group input {
  padding-right: 100px;
}

.modal-dialog .modal-content .modal-body .content-body .go-group .btn {
  padding: 2px 20px;
  position: absolute;
  top: 4px;
  right: 4px;
}

.tab-module {
  justify-content: center;
  align-items: center;
  display: flex;
}

.tab-module ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.tab-module ul li {
  text-align: center;
  color: #434349;
  letter-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border-top: 1px solid #d9dce7;
  border-left: 1px solid #d9dce7;
  border-right: 1px solid #d9dce7;
  border-radius: 4px 4px 0 0;
  outline: none;
  margin-top: 10px;
  margin-right: 6px;
  padding: 12px 20px 18px;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.tab-module ul li.active {
  background: #fff;
  position: relative;
}

.tab-module ul li.active:before {
  content: "";
  background: #fff;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.tabbable-panel {
  border: 1px solid #eee;
  padding: 10px;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0;
}

.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
}

.tabbable-line > .nav-tabs > li > a {
  color: #737373;
  border: 0;
  margin-right: 0;
}

.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid #32465b;
  position: relative;
}

.tabbable-line > .nav-tabs > li.active > a {
  color: #333;
  border: 0;
}

.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}

.tabbable-line > .tab-content {
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  margin-top: -3px;
  padding: 15px 0;
}

.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid #5090f7;
}

.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
  color: #333;
  border: 0;
  background: none !important;
}

.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0;
}

.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

.accordion-wrapper {
  border-bottom: 1px solid #dadade;
  flex-direction: column;
  display: flex;
}

.accordion-wrapper .accordion-header {
  padding: .625rem;
  display: flex;
}

.accordion-wrapper .accordion-header:hover {
  background-color: #fafafc;
}

.accordion-wrapper .accordion-body {
  margin-left: 60px;
  display: block;
}

.accordion-wrapper.accordion-expand .accordion-header {
  cursor: unset;
}

.accordion-wrapper.accordion-expand .accordion-header:hover {
  background-color: #0000;
}

.accordion-wrapper.accordion-expand .accordion-header .toggle .fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.accordion-wrapper.accordion-expand .accordion-body {
  display: block;
}

.sidebar {
  z-index: 1;
  white-space: nowrap;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  width: 5%;
  height: 100%;
  transition: all .5s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.sidebar .admin-panel-text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
  padding: 0;
  display: flex;
}

.sidebar .admin-panel-text .admin-text {
  font-size: 17px;
  font-weight: 500;
}

.sidebar .admin-panel-text .sub-text {
  color: #8a8a91;
  margin-top: -15px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 300;
}

.sidebar .admin-panel-text img {
  width: 16px;
  height: 16px;
  margin: 2px 10px;
}

.sidebar a {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 15px;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.sidebar a .expand-div {
  border-radius: 50px;
  max-width: 170px;
  padding-right: 55px;
}

.sidebar a .expand-div:hover {
  background: #f2f2f7;
}

.sidebar a .active, .sidebar a .active:hover {
  background: #e1e1ea;
}

.sidebar a .category-icon {
  border-radius: 50px;
  padding: 15px 18px;
}

.sidebar a .category-icon img {
  width: 18px;
}

.sidebar a .logout-icon {
  color: #383a3f;
  padding: 12px !important;
}

.sidebar .closebtn {
  color: #0c6e37;
  margin-left: 50px;
  font-size: 36px;
  position: absolute;
  top: 0;
  right: 25px;
}

.expand-text {
  visibility: visible !important;
}

.icon-text {
  vertical-align: middle;
  text-transform: capitalize;
  color: #383a3f;
  visibility: hidden;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.content-container {
  margin-left: 5%;
  transition: margin-left .5s;
  padding: 16px !important;
}

.skeleton-loader-container {
  margin: 1rem auto;
}

.skeleton-loader-container ul {
  padding-left: 0;
  list-style-type: none;
}

.skeleton-loader-container ul li {
  align-items: flex-start;
  margin-bottom: .5rem;
  padding: 10px;
  display: flex;
}

.skeleton-loader-container ul li .circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-right: 1rem;
  overflow: hidden;
}

.skeleton-loader-container ul li .loader {
  position: relative;
  box-shadow: 0 1px 1px #f2f3f8;
}

.skeleton-loader-container ul li .loader:before {
  content: "";
  -ms-animation: progress 1.2s infinite ease;
  background: #f2f2f7 linear-gradient(90deg, #fff0, #fffc, #fff0) 0 0 / 100px 100% no-repeat;
  width: 100%;
  height: 100%;
  -webkit-animation: 1.2s infinite progress;
  -moz-animation: 1.2s infinite progress;
  position: absolute;
}

.skeleton-loader-container ul li .info-wrapper {
  flex: 1;
}

.skeleton-loader-container ul li .info-wrapper div {
  border-radius: 2px;
  overflow: hidden;
}

.skeleton-loader-container ul li .info-wrapper div:not(:last-child) {
  margin-bottom: 10px;
}

.skeleton-loader-container ul li .large {
  width: 80%;
  height: .5rem;
}

.skeleton-loader-container ul li .medium {
  width: 50%;
  height: .3rem;
}

.skeleton-loader-background {
  background: #d3d3d3;
  width: 100%;
  height: 15px;
  display: block;
}

.skeleton-loader-gradient {
  background-color: #d3d3d3;
  background-image: linear-gradient(to right, #fff0, #ffffff80 50%, #fff0 80%), none;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  width: 100%;
  height: 100%;
  display: block;
}

.skeleton-loader, .skeleton-loader:empty {
  background-color: #d3d3d3;
  background-image: linear-gradient(to right, #fff0, #ffffff80 50%, #fff0 80%), none;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  width: 100%;
  height: 100%;
  animation: 1s infinite shine;
  display: block;
}

@keyframes shine {
  to {
    background-position: 100% 0;
  }
}

.skeleton-box {
  background-color: #dddbdd;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.skeleton-box:after {
  content: "";
  background-image: linear-gradient(90deg, #fff0 0, #fff3 20%, #ffffff80 60%, #fff0);
  animation: 1s infinite shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.video-player-container .container, .video-player-container .video-controls, .video-player-container .video-timer, .video-player-container .options {
  justify-content: center;
  align-items: center;
  display: flex;
}

.video-player-container .hidden {
  display: none;
}

.video-player-container svg {
  fill: #fff;
  stroke: #fff;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

.video-player-container .playback-animation {
  pointer-events: none;
  opacity: 0;
  background-color: #0009;
  border-radius: 80px;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
}

.video-player-container .container {
  -webkit-user-select: none;
  user-select: none;
  aspect-ratio: 16 / 9;
  background: #222;
  border-radius: 5px;
  width: 98%;
  max-width: 900px;
  height: 400px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px #0000001a;
}

.video-player-container .container.fullscreen {
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
}

.video-player-container .wrapper {
  z-index: 1;
  opacity: 0;
  transition: all 80ms;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
}

.video-player-container .container.show-controls .wrapper {
  opacity: 1;
  transition: all .13s;
  bottom: 0;
}

.video-player-container .wrapper:before {
  content: "";
  z-index: -1;
  pointer-events: none;
  background: linear-gradient(to top, #000000b3, #0000);
  width: 100%;
  height: calc(100% + 35px);
  position: absolute;
  bottom: 0;
}

.video-player-container .video-timeline {
  cursor: pointer;
  width: 100%;
  height: 7px;
}

.video-player-container .video-timeline .progress-area {
  background: #fff9;
  height: 3px;
  position: relative;
}

.video-player-container .progress-area span {
  color: #fff;
  pointer-events: none;
  font-size: 13px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.video-player-container .progress-area .progress-bar {
  background: #0c6e37;
  width: 0%;
  height: 100%;
  position: relative;
}

.video-player-container .progress-bar:before {
  content: "";
  background: #0c6e37;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.video-player-container .progress-bar:before, .video-player-container .progress-area span {
  display: none;
}

.video-player-container .video-timeline:hover .progress-bar:before, .video-player-container .video-timeline:hover .progress-area span {
  display: block;
}

.video-player-container .wrapper .video-controls {
  padding: 5px 20px 10px;
}

.video-player-container .video-controls .options {
  width: 100%;
}

.video-player-container .video-controls .options:first-child {
  justify-content: flex-start;
}

.video-player-container .video-controls .options:last-child {
  justify-content: flex-end;
}

.video-player-container .options button {
  cursor: pointer;
  color: #efefef;
  background: none;
  border: none;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  font-size: 19px;
  transition: all .3s;
}

.video-player-container .options button :where(i, span) {
  width: 100%;
  height: 100%;
  line-height: 40px;
}

.video-player-container .options button:hover :where(i, span) {
  color: #fff;
}

.video-player-container .options button:active :where(i, span) {
  transform: scale(.9);
}

.video-player-container .options button span {
  font-size: 23px;
}

.video-player-container .options input {
  accent-color: #0c6e37;
  max-width: 75px;
  height: 4px;
  margin-left: 3px;
}

.video-player-container .options .video-timer {
  color: #efefef;
  margin-left: 15px;
  font-size: 14px;
}

.video-player-container .options .video-timer p {
  margin-bottom: 0 !important;
}

.video-player-container .video-timer .separator {
  margin: 0 5px;
  font-family: Open sans;
  font-size: 16px;
}

.video-player-container .playback-content {
  display: flex;
  position: relative;
}

.video-player-container .playback-content .speed-options {
  opacity: 0;
  pointer-events: none;
  background: #ffffffe6;
  border-radius: 4px;
  width: 95px;
  padding-left: 0;
  list-style: none;
  transition: opacity .13s;
  position: absolute;
  bottom: 40px;
  left: -40px;
  overflow: hidden;
  box-shadow: 0 10px 20px #0000001a;
}

.video-player-container .playback-content .speed-options.show {
  opacity: 1;
  pointer-events: auto;
}

.video-player-container .speed-options li {
  cursor: pointer;
  color: #222;
  margin: 2px 0;
  padding: 5px 0 5px 15px;
  font-size: 14px;
  transition: all .1s;
}

.video-player-container .speed-options li:where(:first-child, :last-child) {
  margin: 0;
}

.video-player-container .speed-options li:hover {
  background: #dfdfdf;
}

.video-player-container .speed-options li.active {
  color: #fff;
  background: #0c6e37;
}

.video-player-container .container video {
  z-index: 0;
  width: 100%;
  height: 100%;
}

@media screen and (width <= 540px) {
  .video-player-container .wrapper .video-controls {
    padding: 3px 10px 7px;
  }

  .video-player-container .options input, .video-player-container .progress-area span {
    display: none !important;
  }

  .video-player-container .options button {
    width: 30px;
    height: 30px;
    font-size: 17px;
  }

  .video-player-container .options .video-timer {
    margin-left: 5px;
  }

  .video-player-container .video-timer .separator {
    margin: 0 2px;
    font-size: 14px;
  }

  .video-player-container .options button :where(i, span) {
    line-height: 30px;
  }

  .video-player-container .options button span {
    font-size: 21px;
  }

  .video-player-container .options .video-timer, .video-player-container .progress-area span, .video-player-container .speed-options li {
    font-size: 12px;
  }

  .video-player-container .playback-content .speed-options {
    width: 75px;
    bottom: 30px;
    left: -30px;
  }

  .video-player-container .speed-options li {
    margin: 1px 0;
    padding: 3px 0 3px 10px;
  }

  .video-player-container .right .pic-in-pic {
    display: none;
  }
}

.header .navbar {
  box-shadow: none;
  background: #fff;
}

.header .navbar .navbar-brand {
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
  display: flex;
}

.header .navbar .navbar-brand .brand-name {
  color: #000;
  text-transform: lowercase;
  margin: 0;
  padding: 5px 15px;
  font-family: Poppins;
  font-size: 30px;
  font-weight: bold;
}

.header .navbar .navbar-brand img {
  max-height: 50px;
}

.header .navbar .profile {
  cursor: pointer;
}

.header .navbar .profile .btn:focus {
  box-shadow: none;
}

.header .navbar .profile .profile-name {
  color: #495057;
  font-weight: 600;
}

.header .navbar .profile .profile-name.btn.dropdown-toggle {
  font-size: 12px;
}

.header .navbar .profile .profile-name span {
  color: #646c9a;
  font-weight: 400;
}

.header .navbar .profile .btn.dropdown-toggle {
  text-transform: capitalize;
  font-size: 14px;
}

.header .navbar .profile .placeholder {
  border: 2px solid #d9dce7;
  border-radius: 50%;
  align-self: center;
  max-height: 34px;
  margin-left: .5em;
}

.search-container {
  display: flex;
}

.search-container form {
  border: 1px solid #d9dce7;
  border-radius: 4px;
  height: 48px;
  margin: 5px;
  padding: 0 4px;
}

.search-container form .fd-1 {
  width: 100%;
  padding-right: 13px;
  position: relative;
}

.search-container form .fd-1 label {
  justify-content: left;
  align-items: left;
  color: #646c9a;
  font-size: 10px;
}

.search-container form .fd-1 .form-control, .search-container form .fd-1 .btn {
  color: #495057;
  background: none;
  border: none;
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 12px;
}

.search-container form .fd-1 .form-control:focus, .search-container form .fd-1 .btn:focus {
  border: none !important;
}

.search-container form .fd-2 {
  position: relative;
}

.search-container form .fd-2 .form-control, .search-container form .fd-2 .btn {
  color: #495057;
  background: none;
  border: none;
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 12px;
}

.search-container form .fd-2 .form-control:focus, .search-container form .fd-2 .btn:focus {
  border: none !important;
}

.search-container form .search-btn {
  color: #fff;
  background: #0c6e37;
  border-radius: 2px;
  padding: 7px 11px;
}

.search-container form .search-btn:hover {
  background: #0a582c;
}

.multiselect-native-select {
  margin-right: .5rem;
}

.multiselect-native-select .btn {
  text-align: left;
  justify-content: left;
}

.multiselect-native-select .btn:focus {
  box-shadow: none;
}

.multiselect-container {
  min-width: 14rem;
}

.multiselect-container > li {
  padding: 6px 10px;
  text-decoration: none;
}

.multiselect-container > li:first-child {
  border-radius: 4px 4px 0 0;
}

.multiselect-container > li:last-child {
  border-radius: 0 0 4px 4px;
}

.multiselect-container > li:hover, .multiselect-container > li.active {
  background-color: #f6f6f9;
}

.multiselect-container > li a label {
  font-size: 12px !important;
}

.multiselect-container > li a:hover {
  text-decoration: none;
}

.multiselect-container label {
  justify-content: left;
  padding: 0 !important;
  font-size: 14px !important;
}

.multiselect-container label input {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 10px !important;
}

.heading-section {
  float: right;
  padding: 15px 0;
}

.heading-section .display-result {
  letter-spacing: 0;
  text-transform: none;
  color: #646c9a;
  font-size: 14px;
  font-weight: 400;
}

.heading-section .display-result a {
  cursor: pointer;
  color: #646c9a;
}

.heading-section .display-result a i {
  vertical-align: bottom;
}

.heading-section .display-result a:hover {
  color: #9398b8;
}

.heading-section .call-to-action .btn .rotating {
  margin-right: 6px;
}

.heading-section .call-to-action .date-picker-comp {
  margin: 0 17px;
  position: relative;
}

.heading-section .call-to-action .date-picker-comp:before, .heading-section .call-to-action .date-picker-comp:after {
  content: "";
  border-right: 1px solid #e3e5ed;
  height: 70%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.heading-section .call-to-action .date-picker-comp:before {
  left: -15px;
}

.heading-section .call-to-action .date-picker-comp:after {
  right: -15px;
}

.heading-section .call-to-action .date-picker-comp label {
  float: left;
  text-align: center;
  color: #646c9a;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 14px;
  display: flex;
}

.heading-section .call-to-action .date-picker-comp .date-picker-wrapper {
  float: right;
  position: relative;
}

.heading-section .call-to-action .date-picker-comp .date-picker-wrapper i {
  color: #646c9a;
  cursor: pointer;
  padding: 0;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.heading-section .call-to-action .date-picker-comp .date-picker-wrapper .form-control {
  width: 150px;
  height: calc(1.5em + .75rem - 1px);
  padding-right: 30px;
  font-size: 14px;
  margin-right: 0 !important;
}

.heading-section .call-to-action .btn-group {
  margin-left: 15px;
}

.heading-section .call-to-action .btn-group .or {
  width: 6px;
  position: relative;
}

.heading-section .call-to-action .btn-group .or:before {
  content: "or";
  text-align: center;
  vertical-align: middle;
  color: #646c9a;
  z-index: 99;
  background: #f2f2f7;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading-section .call-to-action .btn-group .btn {
  margin-left: 0;
}

.heading-section .call-to-action .btn {
  margin-left: 15px;
  padding: .375rem 1.34rem;
}

.heading-section .mapping-tool-btn {
  margin-left: 15px;
}

.heading-section h5 {
  text-align: center;
  color: #434349;
  letter-spacing: 3px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin-top: 23px;
  margin-bottom: 23px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.heading-section h5 .back-btn {
  background-color: #434349;
}

.heading-section h5 .back-btn i {
  color: #fff;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: Poppins;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

textarea {
  overflow: scroll;
}

@media only screen and (width >= 300px) and (width <= 480px) {
  .header .navbar {
    justify-content: center;
    display: flex;
  }

  .er-content {
    top: 60px !important;
  }

  .er-content .title {
    font-size: 22px !important;
  }

  .er-content .er-title img {
    width: 45px !important;
    height: 45px !important;
  }

  .er-content .er-description {
    font-size: 25px !important;
  }

  .er-content .er-description-small {
    font-size: 18px !important;
  }

  .er-content .app-buttons .play-store, .er-content .app-buttons .app-store {
    padding: 10px 35px !important;
    font-size: 15px !important;
  }

  .authentication-container {
    flex-direction: column !important;
  }

  .authentication-container .login-screen-img {
    display: none;
  }

  .authentication-container .login-content {
    text-align: center;
    margin-top: -60%;
    padding: 0 !important;
  }

  .mobile-toggle {
    margin-left: 0% !important;
    display: flex !important;
  }

  .navbar-expand {
    margin-left: 22% !important;
  }

  .content-expand {
    margin-top: -155px !important;
  }

  .sidebar {
    width: 0% !important;
  }

  .sidebar .on-profile {
    display: none !important;
  }

  .sidebar-expand {
    width: 24% !important;
  }

  .sidebar a {
    font-size: 18px;
  }

  .admin-panel-logo {
    width: 45px;
    height: 45px;
  }

  .admin-panel-side-logo {
    display: none !important;
  }

  .admin-text {
    color: #222;
    margin-top: 8px;
    margin-left: 8px;
    font-size: 18px;
    font-weight: 700;
  }

  .admin-panel-text {
    display: none !important;
  }

  .expand-div {
    padding: 0 !important;
  }

  .expand-div .icon-text {
    display: none !important;
  }

  .content-container {
    margin-top: -120px !important;
    margin-left: 0% !important;
  }

  .content-container .btn-share {
    display: block !important;
  }

  .content-container .content_language {
    display: none !important;
  }

  .content-container .content-container__data .item {
    flex: 0 100% !important;
  }

  .content-container .drag-file-container .drag-container {
    border-radius: 20px !important;
    margin: 7px !important;
    padding: 10px !important;
  }

  .content-container .drag-file-container .drag-container .upload-mobile-view {
    text-align: center;
    flex-direction: column;
  }

  .content-container .drag-file-container .left-margin {
    margin-left: 5px !important;
  }

  .content-container .tabs-main-container {
    margin: 120px 10px -20px !important;
  }

  .content-container .tabs-main-container .main-container {
    width: 100% !important;
  }

  .content-container .tabs-main-container .my-content-container {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0 !important;
  }

  .content-container .tabs-main-container .tabs-container {
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
  }

  .content-container .tabs-main-container .tabs-container .filler {
    display: none;
  }

  .content-container .tabs-main-container .tabs-container .active-tab {
    margin-left: -18px !important;
  }

  .content-container .tabs-main-container .tabs-container .tab {
    padding: 5px !important;
    font-size: 15px !important;
  }

  .content-container .tabs-main-container .tabs-container .tab .tab-img {
    height: 17px !important;
  }

  .content-container .tabs-main-container .tabs-container .tab .text {
    margin-right: 20px !important;
  }

  .content-container .container .video-container video {
    width: 100vw;
    height: 65vh !important;
  }

  .content-container .container .video-container .video-controls {
    bottom: -35% !important;
  }

  .content-container .schedule-info {
    padding-left: 10px !important;
    font-size: 15px !important;
  }

  .content-container .container .video-wrapper .controls {
    padding: 5px !important;
    bottom: 0 !important;
  }

  .content-container .profile-container .profile-container__data {
    margin-left: 25px;
    padding: 0 !important;
  }

  .content-container .profile-container .profile-container__data .details {
    margin-left: 70px;
  }

  .content-container .notification-section-mobile {
    display: block !important;
  }

  .content-container .notification-section {
    display: none;
  }

  .content-container .notifications-count {
    flex-direction: column;
    padding: 100px 0 0 !important;
  }

  .content-container .notifications-count .btn-sync {
    margin-top: 20px;
  }

  .content-container .panel-container {
    margin: 20px 0 0 !important;
  }

  .content-container .panel-container .left-container {
    max-width: 100% !important;
    padding: 0 !important;
  }

  .no-posts {
    width: 100%;
    top: 45% !important;
    left: 0% !important;
  }

  .no-posts img {
    height: 150px !important;
  }

  .no-posts p {
    font-size: 15px !important;
  }

  .notification-container .request-access, .btn-upload {
    display: none;
  }

  .btn-upload-mobile {
    color: #fff !important;
    z-index: 1 !important;
    background: #0c6e37 !important;
    border: 0 !important;
    border-radius: 50% !important;
    padding: 8px 15px !important;
    font-size: 25px !important;
    display: block !important;
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
    box-shadow: 0 12px 14px #0e9aa729 !important;
  }

  .btn-refresh {
    z-index: 1;
    position: absolute !important;
    top: 90% !important;
  }

  .btn-scroll {
    display: none;
  }

  .feedback-dashboard-wrapper {
    display: none !important;
  }
}

@media only screen and (width >= 481px) and (width <= 768px) {
  .er-content {
    top: 80px !important;
  }

  .er-content .title {
    font-size: 22px !important;
  }

  .er-content .er-title img {
    width: 45px !important;
    height: 45px !important;
  }

  .er-content .er-description {
    font-size: 25px !important;
  }

  .er-content .er-description-small {
    font-size: 18px !important;
  }

  .er-content .app-buttons .play-store, .er-content .app-buttons .app-store {
    padding: 10px 35px !important;
    font-size: 15px !important;
  }

  .admin-panel-logo {
    width: 80px;
  }

  .sidebar {
    width: 11% !important;
  }

  .sidebar-expand {
    width: 24% !important;
  }

  .content-container {
    margin-left: 10% !important;
  }

  .expand-content {
    margin-left: 23% !important;
  }

  .profile-container .profile-container__data {
    margin-left: 25px;
    padding: 0 !important;
  }

  .profile-container .profile-container__data .details {
    margin-left: 70px;
  }
}

@media only screen and (width >= 769px) and (width <= 1024px) {
  .er-content {
    top: 100px !important;
  }

  .er-content .er-description {
    font-size: 28px !important;
  }

  .er-content .er-description-small {
    font-size: 20px !important;
  }

  .authentication-container {
    flex-direction: column !important;
  }

  .authentication-container img {
    margin-top: 200px !important;
    margin-left: 200px !important;
  }

  .sidebar {
    width: 11% !important;
  }
}

@media only screen and (width >= 820px) and (width <= 1180px) {
  .er-content {
    top: 100px !important;
  }

  .er-content .er-description {
    font-size: 28px !important;
  }

  .er-content .er-description-small {
    font-size: 20px !important;
  }

  .authentication-container {
    flex-direction: column !important;
  }

  .authentication-container img {
    margin-top: 800px !important;
    margin-left: -80px !important;
  }

  .admin-panel-logo {
    width: 80px;
  }

  .sidebar {
    width: 11% !important;
  }

  .sidebar-expand {
    width: 24% !important;
  }

  .content-container {
    margin-left: 10% !important;
  }

  .expand-content {
    margin-left: 23% !important;
  }

  .no-posts {
    margin-top: -25% !important;
  }

  .no-posts img {
    height: 200px !important;
  }

  .no-posts p {
    font-size: 15px !important;
  }

  .profile-container .profile-container__data {
    padding: 0 !important;
  }
}

@media only screen and (width >= 1025px) and (width <= 1200px) {
  .er-content {
    top: 150px !important;
  }

  .er-content .er-description {
    font-size: 30px !important;
  }

  .er-content .er-description-small {
    font-size: 22px !important;
  }

  .authentication-container {
    flex-direction: column !important;
  }
}

@media only screen and (width >= 1201px) {
  .er-content {
    top: 200px !important;
  }
}

@media only screen and (width >= 1201px) and (width <= 1440px) {
  .sidebar {
    width: 6% !important;
  }

  .sidebar-expand {
    width: 14% !important;
  }

  .container .video-container {
    height: 37vh !important;
  }

  .container .video-container video {
    margin-top: 40%;
    height: 70vh !important;
  }

  .container .video-container .video-controls {
    bottom: -74% !important;
  }

  .tabs-container .tab {
    padding: 6px 35px;
  }
}

.fadeMe {
  opacity: .5;
  z-index: 10;
  background: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.fadeMe #loader {
  position: absolute;
  top: 50%;
  left: 40%;
}

.fadeMe p {
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: 40%;
  left: 40%;
}

.loading-container {
  justify-content: center;
  align-items: center;
  height: 80%;
  display: flex;
}

.modal-title {
  color: #383a3f;
  font-size: 16px;
  font-weight: 600;
}

.form-check input {
  margin-top: 7px;
}

.form-check label {
  font-size: 13px;
  font-weight: 400;
}

.mat-form-field-label {
  font-size: 16px;
}

.close-button {
  opacity: .4;
}

.displayErrorMessage {
  color: red;
  font-size: 12px;
}

.check-box-error-container {
  flex-direction: row;
  display: flex;
}

.check-box-container {
  flex: 1;
}

.error-container {
  text-align: right;
  flex: 1;
}

.save-desc {
  resize: none !important;
}

.solid-color {
  color: #0996d7;
}

.outline-color {
  color: #646c9a;
}

.fas, .far {
  font-size: medium;
}

.modal-body {
  font-size: 13px;
  font-weight: 400;
}

.modal-body tbody tr {
  font-size: 12px;
}

.modal-body tbody td {
  cursor: pointer;
}

.modal-dialog {
  margin: 0 auto;
  top: 30%;
}

.message {
  text-align: center;
  opacity: .4;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 4%;
  font-size: 14px;
}

.modal-footer {
  border-top: none !important;
}

.modal-header {
  border-bottom: none !important;
}

.fade-scale {
  opacity: 0;
  transition: all .3s;
  transform: scale(.7);
}

.fade-scale.show {
  opacity: 1;
  transform: scale(1);
}

.slide-in-bottom {
  opacity: 0;
  transition: all .3s;
  transform: translateY(20%);
}

.slide-in-bottom.show {
  opacity: 1;
  transform: translateY(0);
}

.slide-in-bottom .modal-dialog {
  margin: 0 20px 20px 0;
  position: fixed;
  bottom: 0;
  right: 0;
}

.modal-default-close {
  color: #fff;
  opacity: 1;
  z-index: 10;
  border-radius: 23px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 28px;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.feedback-modal-top {
  top: calc(100vh - 470px);
}

.notification-container {
  box-sizing: border-box;
  z-index: 999999;
  font-size: 14px;
  position: fixed;
}

.notification-container button {
  float: right;
  color: #fff;
  text-shadow: 0 1px #fff;
  opacity: .8;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  margin-left: 20px;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  top: -.3em;
  right: -.3em;
}

.notification-container .request-access {
  color: #000;
  opacity: 1;
  text-shadow: none;
  background: #e7e7e7;
  margin-top: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  top: 0;
  right: 0;
}

.top-right {
  transition: transform .6s ease-in-out;
  animation: .7s toast-in-right;
  top: 12px;
  right: 12px;
}

.bottom-right {
  transition: transform .6s ease-in-out;
  animation: .7s toast-in-right;
  bottom: 12px;
  right: 12px;
}

.top-left {
  transition: transform .6s ease-in;
  animation: .7s toast-in-left;
  top: 12px;
  left: 12px;
}

.bottom-left {
  transition: transform .6s ease-in;
  animation: .7s toast-in-left;
  bottom: 12px;
  left: 12px;
}

.notification {
  pointer-events: auto;
  color: #000;
  opacity: .9;
  background: #fff 15px no-repeat;
  border-radius: 3px;
  max-width: 350px;
  max-height: 100px;
  margin: 0 0 15px;
  padding: 30px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px #999;
}

.notification:hover {
  opacity: 1;
  cursor: pointer;
  box-shadow: 0 0 12px #fff;
}

.notification-title {
  height: 18px;
  font-size: 14px;
  font-weight: 700;
  color: #fff !important;
  padding-top: 0 !important;
}

.notification-message {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 18px;
  margin: 0 0 0 -1px;
  overflow: hidden;
}

.notification-image {
  margin-right: 15px;
}

.notification-image img {
  width: 30px;
  margin: 0 !important;
}

.toast {
  color: #fff;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 10px 30px 15px;
  display: flex;
}

.request-access-form {
  min-height: 180px !important;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.feedback-wrapper {
  z-index: 999;
  margin-top: 35vh;
  margin-right: -28px;
  position: absolute;
  right: 0;
  transform: rotate(-90deg);
}

.feedback-wrapper .feedback-button {
  background-color: #636b9f;
  font-size: 10px;
}

.feedback-wrapper .feedback-button span {
  color: #fff;
  font-size: 10px;
}

.feedback-wrapper .feedback-button:focus {
  box-shadow: none;
  outline: none;
}

.feedback-dashboard-wrapper {
  z-index: 12;
  position: fixed;
  top: calc(100vh - 50px);
  right: 26px;
}

.feedback-dashboard-wrapper .feedback-dashboard-button {
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.feedback-dashboard-wrapper .feedback-dashboard-button span {
  color: #fff;
  font-size: 12px;
}

.feedback-dashboard-wrapper .feedback-dashboard-button i {
  color: #fff;
  margin-right: 10px;
}

.feedback-dashboard-wrapper .feedback-dashboard-button .feedback-btn {
  font-size: 15px;
}

.feedback-dashboard-wrapper .feedback-dashboard-button:focus {
  box-shadow: none;
  outline: none;
}

.feedback-loader {
  margin-bottom: 20px;
  margin-right: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.feedback-modal-wrapper {
  border: none;
  width: 300px;
  padding: 0 !important;
}

.feedback-modal-wrapper .close {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.feedback-modal-wrapper .feedback-title {
  color: #fff;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  display: block;
}

.feedback-modal-wrapper .feedback-icons-wrapper {
  background-color: #0c6e37;
  padding: 10px 20px;
  display: flex;
}

.feedback-modal-wrapper .feedback-close {
  color: #fff;
  line-height: unset;
}

.feedback-modal-wrapper .feedback-quote {
  opacity: .05;
  text-align: center;
  font-size: 70px;
}

.feedback-modal-wrapper .feedback-desc {
  color: #868db6;
  text-align: center;
  margin-top: -30px;
  font-size: 10px;
  display: block;
}

.feedback-modal-wrapper .feedback-icon-q {
  text-align: center;
  padding-top: 20px;
}

.feedback-modal-wrapper .feedback-form {
  height: auto;
  min-height: 225px;
  margin: 50px 20px -10px;
  padding: 0;
}

.feedback-modal-wrapper .feedback-form .dropdown-menu {
  width: 100%;
}

.feedback-modal-wrapper .feedback-form .dropdown-toggle {
  background: none;
  border: 1px solid #e0e0e0;
}

.feedback-modal-wrapper .feedback-form .dropdown-toggle:after {
  float: right;
}

.feedback-modal-wrapper .feedback-modal {
  width: 100%;
}

.feedback-modal-wrapper textarea {
  resize: none;
  height: 80px;
}

.feedback-modal-wrapper .feedback-modal {
  display: inline;
}

.feedback-modal-wrapper .feedback-button {
  cursor: pointer;
  color: #fff;
  background-color: #75c018;
  border: none;
  width: 88%;
  font-weight: 400;
}

.feedback-modal-wrapper .modal-body {
  padding: 0 !important;
}

.error-message {
  color: #dc3545;
  text-align: center;
}

.custom-dialog-dropdown button {
  text-align: left;
  width: 100%;
}

.custom-dialog-dropdown .active-item {
  color: #0c6e37 !important;
  background: #0000001f !important;
}

.custom-dialog-container p {
  float: right;
  color: #868db6;
  font-size: 10px;
}
/*# sourceMappingURL=index.5cb38e3b.css.map */
