@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/_tables.scss";
@import "./static/assets/sass/main.scss";
@import "./static/assets/sass/base/variables";

.fadeMe {
    opacity: 0.5;
    background: #000;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;

    #loader {
        position: absolute;
        top: 50%;
        left: 40%;
    }

    p {
        top: 40%;
        position: absolute;
        left: 40%;
        color: #fff;
        font-size: 30px;
    }
}
